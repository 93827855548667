import { useState, useEffect } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { any, bool, func, number, object, string } from 'prop-types';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import BillingDetailsFields from './prebuilt/BillingDetailsFields';
import CheckoutError from './prebuilt/CheckoutError';
import { FilledButton, TitleTwo,
  Block,
  Flex,
  TextGreySmallBold,
  TextLightSmall,
  TextBlackSmall,
  TextBlackSmallThin,
  Loading,
  SecondaryBtnHigh,
  TextLightBold,
  FlexWrapper,
  TextAccent,
  Check,} from 'base/styled';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { ReactComponent as Discount } from 'assets/images/promo.svg';
import { ReactComponent as Default } from 'assets/images/spin-dark.svg';
import network from 'base/network';
import FormTextField from 'base/components/FormTextField';
import PaidPersonalization from './PaidPersonalization';
import { useSelector } from 'react-redux';

const CardElementContainer = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  margin: 30px 0 0;
  select {
    color: red;
    option {
      color: green
    }
  }
  #error {
    border: 1.5px solid#FF5252
  }

  & .StripeElement {
    width: 100%;
    display: block;
    font-family: 'Montserrat', sans-serif;
    font-size: 0.85em;
    font-weight: 100;
    margin: 20px 0;
    padding: 8px 14px;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
      rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    outline: 0;
    border: 1px solid #c6d5dd;
    background: white;
    border-radius: 8px;
    height: 33px;
    width: 100%;
    //color: #85929b;
    color: red;
    
    transition: all 0.2s;
    &:focus-visible {
      outline: none;
    }
  }
`;

const FlexWrap = styled(Flex)`
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FormBox = styled(Block)`
  margin: 0 auto;
  padding: 20px 0;
  display: block;
  width: 700px;
  h2 {
    padding: 20px 0
  }
  h2,
  svg {
    text-align: center;
    margin: 0 auto;
  }
  .logo {
    margin: 20px auto
  }
  @media (max-width: 768px) {
    margin: 0 4px;
    width: auto;
    h2 {
      text-align: center;
    }
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  input {
    height: 31px;
    margin: 8px 0;
    color: #2c2c2c;
    ::placeholder {
      color: #85929b;
    }
  }
  .ElementsApp .InputElement,
  .ElementsApp input,
  .InputContainer {
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
  }
  .right {
    margin-right: 10px;
  }
  .select {
    color: #2c2c2c;
    font-family: "Montserrat", sans-serif;
    option {
      color: #2c2c2c;
      font-family: "Montserrat", sans-serif;
    }
  }
  .light {
    background: grey;
    color: transparent;
    height: 1px;
    border: none;
    width: 100%;
  }
  p {
    white-space: nowrap;
  }
`;

const CardInfo = styled.div`
  width: 55%;
  display: flex;
  padding: 20px;
  flex-direction: column;
  @media (max-width: 768px) {
    width: auto;
  }
  .form-fields {
     .right {
       margin-right: 10px;
     }
  }
`;

const BillInfo = styled.div`
  width: 40%;
  background: #F9F9F9;
  padding: 5px 15px;
  border-radius: 15px;
  margin: 10px;
  @media (max-width: 768px) {
    width: auto;
  }
`;

const BillRow = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  .after {
    border-bottom: 1px dotted 1px #626262;
    background-image: linear-gradient(
      to right,
      #626262 33%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: bottom;
    background-size: 6px 1.5px;
    background-repeat: repeat-x;
    width: 100%;
    height: 24px;
    bottom: 0;
    left: 0;
  }
  .bold {
    font-weight: 600;
    white-space: nowrap;
  }
  .relative {
    position: relative;
    margin-bottom: 10px;
  }
  .absolute {
    position: absolute;
    top: 15px;
  }
`;

const Buttons = styled.div`
  display: flex;
  padding: 0 20px;
  margin-left: auto;
  button {
    margin-left: 10px;
  }
`;

const Promo = styled.div`
  position: relative;
  *{font-size: 12px;}
  svg {
    position: absolute;
    right: 8px;
    top: 10px;
  }

  .btn {
    position: absolute;
    font-weight: 500;
    font-size: 10px;
    background: #e8e8e8;
    top: 3px;
    right: 5px;
    color: #000;
    border: none;
    height: 25px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    padding: 3px 8px;
    align-items: center;
    &.active {
      background: #3b3e45;
      color:#fff;      
    }
  }
  input {
    padding-left: 15px;
    margin: 0;
    &:focus-visible {
      outline: none;
    }
  }
   .spinner {
    animation: spin-animation 0.7s infinite;
    display: inline-block;
    top: 5px;
  }

  @keyframes spin-animation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
`;

const Load = styled(Loading)`
  width: 280px;
  height: 385px;
  margin: 10px 10px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const TextGreen = styled.p`
  font-weight: 600;
  font-size: 12px;
  color: #41b34d;
`;

const Discounted = styled.div`
  min-height: 45px;
  padding: 5px 0;
  p {
    margin: 3px 0;
  }
`;

const TextError = styled(TextAccent)`
  font-weight: 500;
  font-size: 10px;
  line-height: 2;
  background: #FFE8E8;
  border-radius: 10px;
  padding: 3px 15px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  position: relative;
  white-space: normal;
  p {
    white-space: normal;
  }
  &::after {
    position: absolute;
    content: '';
    display: block;
    left: 6%;
    top: -35%;
    width: 0;
    height: 0;
    border-left: 13px solid transparent;
    border-right: 13px solid transparent;
    border-bottom: 13px solid #FFE8E8;
    clear: both;
    z-index: -1;
  }
`;

const Card = styled.div`
  background: linear-gradient(22deg, #e0e0e0 0%, #939393 100%);
  border-radius: 10px;
  position: relative;
  height: 50px;
  margin-top: 25px;
`;

const CheckDark = styled(Check)`
  height: 100%!important;
  width: 25px;
  &:checked + span {
    border: 1px solid #3B3E45;
    border-radius: 50%
  }
  & + span {
    width: 16px;
    min-width: 16px;
    height: 16px;
    top: 14px;
    left: 14px;
    border: 1px solid #3B3E45;
    background: #fff;
    border-radius: 50%
  }
  &:checked + span:after {
    background: #3B3E45;
    width: 10px;
    height: 10px;
    top: 2px;
    left: 2px;
    border: 1px solid #3B3E45;
    border-radius: 50%
  }
`;

const FlexBetween = styled(Flex)`
  justify-content: space-between;
  padding: 0 15px 0 40px;
  p {
    margin: -5px 0 0
  }
`;

const CheckoutSubscribtionForm = ({
  handleSubscribeOff,
  setSuccess,
  details,
  choosedMonth,
  choosed,
  active,
  cur,
  term,
  personalisation,
  personalisations,
  setPersonalisations,
  add
}) => {
  const { t: homeT } = useTranslation('home');
  const [checkoutError, setCheckoutError] = useState();
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [accountName, setAccountName] = useState(details?.name);
  const [stats, setStats] = useState('loading');
  const { t: companyT } = useTranslation('company');
  const [promo, setPromo] = useState('');
  const [taxes, setTaxes] = useState('0');
  const [activeBtn, setActiveBtn] = useState(false);
  const [total, setTotal] = useState(choosed?.stripe_payment_amount);
  const [message, setMessage] = useState('');
  const [discount, setDiscount] = useState();
  const [amount, setAmount] = useState();
  const [currencyPlan, setCurrencyPlan] = useState(choosed?.currency);
  const [status, setStatus] = useState('');
  const {  payment } = useSelector(
    (state) => state.accountReducer
  );
  const stripe = useStripe();
  const elements = useElements();
  const [sentPromo, setSentPromo] = useState(false);
 
  function handleName(e) {
    setAccountName(e.target.value);
  }
  
  function handlePromo(e) {
    setPromo(e.target.value);
    setActiveBtn(true);
  }

  function handleRegion(val) {
    setRegion(val);
  }

  function handleCountry(val) {
    setCountry(val);
  }
  
  useEffect(() => {
    setCurrencyPlan(choosed?.currency);
    //setAmount(choosed?.stripe_payment_amount);
    /* eslint-disable */
  }, []);
  /* eslint-enable */
 
  useEffect(() => {

    if (country === 'Canada' && !discount) {
      switch (region) {
      case 'Alberta':
        setTaxes('5');
        setTotal(
          +amount + Math.floor(amount * 0.05)
        );
        break;
      case 'British Columbia':
        setTaxes('12');
        setTotal(
          +amount + Math.floor(amount * 0.12)
        );
        break;
      case 'Manitoba':
        setTaxes('5');
        setTotal(
          +amount + Math.floor(amount * 0.05)
        );
        break;
      case 'New Brunswick':
        setTaxes('15');
        setTotal(
          +amount + Math.floor(amount * 0.15)
        );
        break;
      case 'Newfoundland and Labrador':
        setTaxes('15');
        setTotal(
          +amount + Math.floor(amount * 0.15)
        );
        break;
      case 'Northwest Territories':
        setTaxes('15');
        setTotal(
          +amount + Math.floor(amount * 0.15)
        );
        break;
      case 'Nova Scotia':
        setTaxes('15');
        setTotal(
          +amount + Math.floor(amount * 0.15)
        );
        break;
      case 'Nunavut':
        setTaxes('5');
        setTotal(
          +amount + Math.floor(amount * 0.05)
        );
        break;
      case 'Ontario':
        setTaxes('13');
        setTotal(
          +amount + Math.floor(amount * 0.13)
        );
        break;
      case 'Prince Edward Island':
        setTaxes('15');
        setTotal(
          +amount + Math.floor(amount * 0.15)
        );
        break;
      case 'Quebec':
        setTaxes('5');
        setTotal(
          +amount + Math.floor(amount * 0.05)
        );
        break;
      case 'Saskatchewan':
        setTaxes('5');
        setTotal(
          +amount + Math.floor(amount * 0.05)
        );
        break;
      case 'Yukon':
        setTaxes('5');
        setTotal(
          +amount + Math.floor(amount * 0.05)
        );
        break;
      default:
        setTaxes('0');
        setTotal(amount);
      }
    } else if (country === 'Canada' && discount) {
      switch (region) {
      case 'Alberta':
        setTaxes('5');
        setTotal(
          +amount - discount.discount + (amount - discount.discount)/100*5
        );
        break;
      case 'British Columbia':
        setTaxes('12');
        setTotal(
          +amount - discount.discount + (amount - discount.discount)/100*12
        );
        break;
      case 'Manitoba':
        setTaxes('5');
        setTotal(
          +amount - discount.discount + (amount - discount.discount)/100*5
        );
        break;
      case 'New Brunswick':
        setTaxes('15');
        setTotal(
          +amount - discount.discount + (amount - discount.discount)/100*15
        );
        break;
      case 'Newfoundland and Labrador':
        setTaxes('15');
        setTotal(
          +amount - discount.discount + (amount - discount.discount)/100*15
        );
        break;
      case 'Northwest Territories':
        setTaxes('15');
        setTotal(
          +amount - discount.discount + (amount - discount.discount)/100*15
        );
        break;
      case 'Nova Scotia':
        setTaxes('15');
        setTotal(
          +amount - discount.discount + (amount - discount.discount)/100*15
        );
        break;
      case 'Nunavut':
        setTaxes('5');
        setTotal(
          +amount - discount.discount + (amount - discount.discount)/100*5
        );
        break;
      case 'Ontario':
        setTaxes('13');
        setTotal(
          +amount - discount.discount + (amount - discount.discount)/100*13
        );
        break;
      case 'Prince Edward Island':
        setTaxes('15');
        setTotal(
          +amount - discount.discount + (amount - discount.discount)/100*15
        );
        break;
      case 'Quebec':
        setTaxes('5');
        setTotal(
          +amount - discount.discount + (amount - discount.discount)/100*5
        );
        break;
      case 'Saskatchewan':
        setTaxes('5');
        setTotal(
          +amount - discount.discount + (amount - discount.discount)/100*5
        );
        break;
      case 'Yukon':
        setTaxes('5');
        setTotal(
          +amount - discount.discount + (amount - discount.discount)/100*5
        );
        break;
      default:
        setTaxes('0');
        setTotal(amount);
      }

    } else {
      setTaxes('0');
      setTotal(amount || choosed?.stripe_payment_amount);
    }
  /* eslint-disable */
  }, [region, active, details]);
  /* eslint-enable */

  useEffect(() => {
    if(personalisation && personalisation.id)
      getTotal();
    /* eslint-disable */
  }, [term, personalisation, personalisations]);
    /* eslint-enable */

  useEffect(() => {
    if(discount && country === 'Canada'){
      if(taxes == 0){
        setTotal(+total - discount.discount);
      } else {
        setTotal(+total + (total/100*taxes));
      }
    }
    /* eslint-disable */
  }, [discount, checkoutError]);
    /* eslint-enable */ 

  const getTotal = async () => {
    setStats('loading');

    await network
      .get(`/api/account/subscription/prorate`, {params:
      {subscription_id: choosed.id, coupon: promo, personalisation_id: personalisations ? personalisation?.id : ''}})
      .then((response) => {
        setTotal(response.data.results.total);
        setAmount(response.data.results.amount);
        setStats('success');
      })
      .catch((error) => console.log(error));
  };

  const postPromo = async () => {
    setMessage();
    setDiscount();
   
    const formData = {
      subscription_id: choosed?.id,
      coupon_code: promo,
      personalisation_id: personalisations ? personalisation?.id : ''
    };
    
    let res = await network
      .post('/api/account/subscription/apply_coupon', formData)
      .then(({ data }) => {
        setTotal(data?.results.total ? data?.results.total : total );
        setSentPromo(false);
        return data;
      })
      .catch((error) => console.log(error));
    if (res.results === 'Invalid Coupon') {
      setMessage(res.results);
    } else if(country !== 'Canada') {
      setDiscount(res.results);
      setTotal(res.results.total);
    } else {
      setDiscount(res?.results);
      setTotal(res.results.total);
    }
  };

  function handlePromoSubmit(e) {
    e.preventDefault();
    setSentPromo(true);
    postPromo();
  }

  const handleCardDetailsChange = (ev) => {
    ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
    setErrorText();
  };
  const [errorText, setErrorText] = useState();
  const [card, setCard] = useState(false);
  function handleCard() {
    setCard(!card);
  }

  const handleFormSubmit = async (ev) => {
    ev.preventDefault();
    setStatus('loading');
    
    const formData = {
      company_name: accountName,
      subscription: {
        id: choosed?.id,
        address: ev.target.address.value,
        city: ev.target.city.value,
        country: country,
        province_or_state_or_region: region,
        coupon_code: promo,
        stripe_token: '',
        personalisation_id: personalisations ? personalisation?.id : '',
        add_personalisation: personalisations ? "1" : "0"
      },
    };
  
    if (!card) {
      const cardElement = elements.getElement('card');
      try {
        const result = await stripe.createToken(cardElement);
        if (result.error) {
          throw new Error(result.error.message);
        }
        formData.subscription.stripe_token = result.token.id;
      } catch (error) {
        setErrorText(error.message);
        setStatus('failure');
        return;
      }
    }
  
    try {
      const res = await network.post('/api/account/subscription', formData);
      await handleAccountDetails(res);
    } catch (error) {
      setErrorText(error?.response?.data?.error || 'An error occurred.');
      setStatus('failure');
    }
  };
  
  const handleAccountDetails = async (res) => {
    try {
      const response = await network.get('/api/account/details');
      if (response?.data?.results?.should_send_paid_acc && typeof dataLayer !== 'undefined') {
        dataLayer.push({
          event: 'eventTracking',
          eventAction: 'Signed Up for Paid Account',
        });
        try {
          await network.post('/api/account/send_paid_acc');
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
    }
  
    if (res.data?.results.action_required) {
      window.open(res.data?.results.redirect_user_to, '_self');
    } else {
      setSuccess(res.data?.results);
      setStatus('success');
    }
  };
  

  const iframeStyles = {
    base: {
      color: '#2C2C2C',
      fontSize: '14px',
      fontWeight: '400',
      fontFamily: '"Montserrat", sans-serif',
      // border: "1px solid #C6D5DD",
      iconColor: '#85929b38',
      '::placeholder': {
        color: '#85929B',
        fontSize: '14px',
        fontWeight: '300',
        fontFamily: 'Montserrat, sans-serif',
      },
    },
    invalid: {
      iconColor: '#FFC7EE',
      color: '#FFC7EE',
    },
    complete: {
      iconColor: '#cbf4c9',
    },
  };

  const cardElementOpts = {
    iconStyle: 'solid',
    style: iframeStyles,
    hidePostalCode: true,
  };

  return (
    <FormBox>
      <TitleTwo>{companyT('subscribtionPayment')}</TitleTwo>
      <Form onSubmit={handleFormSubmit}>
        <FlexWrap>
          <CardInfo>
            <Logo className='logo'/>
            <div className='form-fields'>
              <BillingDetailsFields
                country={country}
                region={region}
                handleCountry={handleCountry}
                handleRegion={handleRegion}
                name={accountName}
                handleName={handleName}
                card={card}
              /> 
            </div>
            <div>
              <CardElementContainer>
                <CardElement
                  options={cardElementOpts}
                  onChange={handleCardDetailsChange}
                  id={errorText ? 'error' : ''}
                />
              </CardElementContainer>
            </div>
            {(payment && payment.length > 0) && <Card>
              <CheckDark onChange={handleCard} 
                checked={card}></CheckDark>
              <span></span>
              <FlexBetween><p>Use current card </p>
                <p>{'**** **** ****' + (payment ? payment[0]?.last4 :'****')}</p>
              </FlexBetween>
              
            </Card>}
            {errorText && <TextError>{errorText}</TextError>}
          </CardInfo>
          {stats !== 'loading' ? (
            <BillInfo>
              <BillRow>
                <div className="relative">
                  <TextBlackSmallThin>{companyT('newPlan')}</TextBlackSmallThin>
                  <TextLightSmall className="absolute">
                    {companyT('billed')}
                    {term === 1 ? 'annually' : 'monthly'}
                  </TextLightSmall>
                </div>
                <div className="after"></div>
                <TextBlackSmall className="bold">
                  
                  {choosedMonth?.amount || choosed.lead_limit}{' leads per month '}
                  {'(' + ( cur || currencyPlan ) + ')'}
                </TextBlackSmall>
              </BillRow>
              <BillRow>
                <TextBlackSmallThin>{companyT('amount')}</TextBlackSmallThin>
                <div className="after"></div>
                <TextBlackSmall className="bold">
                  {currencyPlan === 'USD' && '$' ||
                  currencyPlan === 'CAD' && '$' ||
                  currencyPlan === 'EUR' && '€' ||
                  currencyPlan === 'GBP' && '£'
                  }
                  { parseFloat(amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") } {currencyPlan}
                </TextBlackSmall>
              </BillRow>
              <BillRow>
                <TextBlackSmallThin>{companyT('taxes')}</TextBlackSmallThin>
                <div className="after"></div>
                <TextBlackSmall className="bold">{taxes} %</TextBlackSmall>
              </BillRow>
              <PaidPersonalization form={true}  personalisations={personalisations}
                setPersonalisations={setPersonalisations} add={add} cur={currencyPlan} />
              <TextBlackSmallThin>{companyT('promo')}</TextBlackSmallThin>
              <Promo>
                { discount ? <Discount /> : 
                  sentPromo ? <Default className="spinner"/> :
                    <div
                      className={activeBtn ? 'btn active' : 'btn'}
                      onClick={handlePromoSubmit}
                    >Submit</div>}
                <FormTextField
                  value={promo}
                  onChange={handlePromo}
                  placeholder={companyT('promoCode')}
                />
                <Discounted>
                  <TextGreySmallBold>{message}</TextGreySmallBold>
                  {discount && (
                    <>
                      <FlexWrapper>
                        <TextLightBold>Old price:</TextLightBold>
                        <TextLightBold>
                          {currencyPlan === 'USD' && '$' ||
                          currencyPlan === 'CAD' && '$' ||
                          currencyPlan === 'EUR' && '€' ||
                          currencyPlan === 'GBP' && '£'
                          }
                          { parseFloat(amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") } {currencyPlan}
                        </TextLightBold>
                      </FlexWrapper>
                      <FlexWrapper>
                        <TextGreen> {companyT('discount')}</TextGreen>
                        <TextGreen>{currencyPlan === 'USD' && '$' ||
                          currencyPlan === 'CAD' && '$' ||
                          currencyPlan === 'EUR' && '€' ||
                          currencyPlan === 'GBP' && '£'
                        }{discount.discount + ' ' + currencyPlan}</TextGreen>
                      </FlexWrapper>
                    </>  
                  )}
                </Discounted>
              </Promo>
              <BillRow>
                <TextBlackSmall>{companyT('total')}:</TextBlackSmall>
                <TextBlackSmall className="bold">
                  {currencyPlan === 'USD' && '$' ||
                  currencyPlan === 'CAD' && '$' ||
                  currencyPlan === 'EUR' && '€' ||
                  currencyPlan === 'GBP' && '£'
                  }
                  {total && parseFloat(total).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} {currencyPlan}
                </TextBlackSmall>
              </BillRow>
            </BillInfo>
          )
            : <Load />
          }
        </FlexWrap>
        {checkoutError && <CheckoutError>{checkoutError}</CheckoutError>}
        <Buttons>
          <SecondaryBtnHigh type="reset" onClick={handleSubscribeOff}>
            {homeT('cancel')}
          </SecondaryBtnHigh>
          <FilledButton>
            {status === 'loading' ? 'sending...' : homeT('confirm')}
          </FilledButton>
        </Buttons>
      </Form>
    </FormBox>
  );
};

export default CheckoutSubscribtionForm;

CheckoutSubscribtionForm.propTypes = {
  setSubscribtionSuccess: func,
  handleSubscribeOff: func,
  setSuccess: func,
  details: any,
  choosedMonth: any,
  choosed: any,
  active: number,
  cur: string,
  term: any,
  personalisation: object,
  personalisations: bool,
  setPersonalisations: func,
  add: any,
  payment: any
};