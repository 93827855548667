import { func, bool, any, string, object, number } from 'prop-types';
import Layout from './Layout';
import CheckoutSubscribtionForm from './CheckoutSubscribtionForm';

export default function SubscribeForm({
  handleSubscribeOff,
  setSuccess,
  details,
  choosedMonth,
  choosedAnnual,
  choosed,
  active,
  cur, 
  term,
  personalisation,
  personalisations,
  setPersonalisations,
  add
}) {

  return (
    <>
      <Layout>
        <CheckoutSubscribtionForm 
          handleSubscribeOff={handleSubscribeOff} 
          setSuccess={setSuccess}
          details={details}
          choosedMonth={choosedMonth}
          choosedAnnual={choosedAnnual}
          choosed={choosed}
          active={active}
          cur={cur}
          term={term}
          personalisation={personalisation}
          personalisations={personalisations}
          setPersonalisations={setPersonalisations}
          add={add}
        />
      </Layout>
    </>
  );
}

SubscribeForm.propTypes = {
  handleSubscribeOff: func,
  setSuccess: func,
  choosedAnnual: bool,
  details: any,
  choosedMonth: any,
  choosed: any,
  active: number,
  cur: string,
  term: any,
  add: any,
  personalisations: any,
  setPersonalisation: func,
  personalisation: object,
  setPersonalisations: func
};
