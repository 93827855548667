import { TextField, TextLabel } from "base/styled";
import { string, bool } from 'prop-types';
import styled from "styled-components";

const Input = styled(TextField)`
  color: #2C2C2C;
`;

const FormField = ({ label, type, name, placeholder, className, required }) => {
  return (
    <>
      <TextLabel htmlFor={name}>{label}</TextLabel>
      <Input name={name} type={type} placeholder={placeholder} required={required} className={className} />
    </>
  );
};

export default FormField;

FormField.propTypes = {
  label: string, 
  type: string, 
  name: string, 
  placeholder: string, 
  required: bool, 
  className: string
};
