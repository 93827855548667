import {
  Block,
  TitleTwo
} from 'base/styled';
import { useTranslation } from 'react-i18next';
import { func, any } from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import Layout from './Layout';
import CheckoutForm from './CheckoutForm';

const FormBox = styled(Block)`
  margin: 0 auto;
  padding: 20px 0;
  display: block;
  text-align: center;
  h2,
  svg {
    text-align: center;
    margin: 0 auto;
  }
  svg {
    margin: 20px 0 25px
  }
  @media (max-width: 768px) {
    margin: 0 4px;
    h2 {
      text-align: center;
    }
  }
`;

const Title = styled(TitleTwo)`
  @media (max-width: 768px) {
    text-align: center;
  }
`;

export default function Payment({
  handleSubscribeOff,
  setSuccess,
  details, 
  term, 
  payment
}) {
  const { t: companyT } = useTranslation('company');

  return (
    <FormBox>
      <Title>{companyT('updatePayment')} </Title>
      <Logo />
      <Layout>
        <CheckoutForm handleSubscribeOff={handleSubscribeOff} setSuccess={setSuccess}
          details={details} term={term} payment={payment} />
      </Layout>
    </FormBox>
  );
}

Payment.propTypes = {
  handleSubscribeOff: func,
  setSuccess: func,
  details: any,
  term: any,
  payment: any
};
