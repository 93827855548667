import { useEffect, useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAllBenchmark, fetchAllCustom, fetchAllSummary } from 'store/slices/notifications';
import { useTranslation } from 'react-i18next';
import { fetchUserEdit } from 'store/slices/user';
import { isStatusLoading } from 'base/utils';
import styled from 'styled-components';
import {
  Main,
  Loader,
  FlexWrapper,
  TitleSmallBlack,
  Block,
  FlexWrapperStart,
  Modal,
  Overflow,
  ModalContent,
  TitlePage,
  AccentButton,
  TitleThree,
  SearchForm
} from 'base/styled';
import EmailsTable from './components/EmailsTable';
import { ReactComponent as SearchIcon } from 'assets/images/search.svg';
import { ReactComponent as Success } from 'assets/images/saved.svg';
import { ReactComponent as Plus } from 'assets/images/plus_white.svg';
import { ReactComponent as CloseIcon } from 'assets/images/close.svg';
import LeadQueue from 'base/components/Leads/NewQueue/LeadQueue';
import Responsive from 'context/responsive';

const MainProfile = styled(Main)`
  padding: 80px 0 0 40px;
  flex-direction: column;
`;

const Close = styled.div`
  position: fixed;
  bottom: 4%;
  left: 40%;
  background: #fff;
  border-radius: 50%;
  width: 47px;
  height: 47px;
  padding: 16px;
`;

const Over = styled(Overflow)`
  left: 0px;
  top: 0px;
  z-index: 1;
  height: 100vh;
  @media (max-width: 768px) {
    left: -2px;
  }
`;

const ContentEmail = styled.div`
  padding-top: 20px;
  width: 80%;
  h1 {
    margin: 0 0 10px 0;
    line-height: 54px;
  }
  h3 {
    text-align: left;
    margin: 10px 0 20px 0;
  }
  table {
    margin-bottom: 90px;
  }
  button {
    margin-left: 20px;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SaveBlock = styled(Block)`
  margin: 0 auto;
  padding: 20px 50px;
  .success_icon {
    width: 78%;
  }
`;

const Summary = styled.div`
  
`;

const AddBlock = styled(Block)`
    position: fixed;
    width: 60%;
    top: 0;
    right: 0;
    height: 100%;
    padding: 0;
    overflow: hidden;
    z-index: 10;
    .fluid {
      width: 100;
    }
    @media (max-width: 768px) {
      width: 100%;
      height: 80%;
      border-radius: 0 0 15px 15px;
    }
`;

const Flex = styled(FlexWrapperStart)`
@media (max-width: 768px) {
  flex-direction: column;
}
`;

export default function Index() {
  const dispatch = useDispatch();
  const { status } = useSelector((state) => state.userReducer);
  const { page, customs, summaries, benchmarks } = useSelector(
    (state) => state.notificationsReducer
  );
  const { queues } = useSelector((state) => state.dashboardQueuesReducer);
  const { t } = useTranslation('profileForm');
  const { t: rootT } = useTranslation();
  const [success, setSuccess] = useState();
  const [openAdd, setOpenAdd] = useState();
 
  const ctx = useContext(Responsive);

  useEffect(() => {
    dispatch(fetchAllCustom({ page }));
    dispatch(fetchAllSummary({ page, summaries }));
    dispatch(fetchAllBenchmark({ page, benchmarks }));
    dispatch(fetchUserEdit());
    /* eslint-disable */
  }, [dispatch]);
    /* eslint-enable */

  function handleSuccessOff() {
    setSuccess();
  }

  function handleOpenAdd() {
    setOpenAdd(true);
  }

  function handleOpenAddOff() {
    setOpenAdd();
  }

  const [texts, setTexts] = useState({
    custom: '',
    summary: '',
    benchmark: ''
  });

  const handleTextChange = (type) => (e) => {
    const value = e.target.value;
    setTexts((prev) => ({ ...prev, [type]: value }));

    // Диспетчеризація залежно від очищення поля
    if (value === '') {
      switch (type) {
      case 'custom':
        dispatch(fetchAllCustom({ page }));
        break;
      case 'summary':
        dispatch(fetchAllSummary({ page }));
        break;
      case 'benchmark':
        dispatch(fetchAllBenchmark({ page }));
        break;
      default:
        break;
      }
    }
  };

  const handleSearchSubmit = (type) => (e) => {
    e.preventDefault();
    const query = texts[type];
    dispatch(type === 'custom' ? fetchAllCustom({ page, query }) :
      type === 'summary' ? fetchAllSummary({ page, query }) :
        fetchAllBenchmark({ page, query }));
  };

  return isStatusLoading(status) ? (
    <Loader />
  ) : (
    <MainProfile>
      <FlexWrapper>
        <TitlePage>{t('mails')}</TitlePage>
      </FlexWrapper>
      <Flex>
        <>
          <ContentEmail>
            <Summary>
              <FlexWrapper>
                <TitleThree>{t('emails')}</TitleThree>
                <SearchForm onSubmit={handleSearchSubmit('summary')}>
                  <SearchIcon />
                  <input
                    className='light' type="search" 
                    onChange={handleTextChange('summary')}
                    value={texts.summary}
                  />
                </SearchForm>
              </FlexWrapper>
              <EmailsTable list={summaries.results} />
            </Summary>
            <Summary>
              <FlexWrapper>
                <TitleThree>{t('benchmark')}</TitleThree>
                <SearchForm onSubmit={handleSearchSubmit('benchmark')}>
                  <SearchIcon />
                  <input
                    className='light' type="search" 
                    onChange={handleTextChange('benchmark')}
                    value={texts.benchmark}
                  />
                </SearchForm>
              </FlexWrapper>
              
              <EmailsTable list={benchmarks.results} benchmark={true} />
            </Summary>
            <FlexWrapper>
              <FlexWrapperStart>
                <TitleThree>{t('customQueue')}</TitleThree>
                <AccentButton onClick={handleOpenAdd}>
                  <Plus />
                  {rootT('add')}
                </AccentButton>
              </FlexWrapperStart>
              <SearchForm onSubmit={handleSearchSubmit('custom')}>
                <SearchIcon />
                <input
                  className='light' type="search" 
                  onChange={handleTextChange('custom')}
                  value={texts.custom}
                />
              </SearchForm>
            </FlexWrapper>
            {openAdd &&  (
              <Modal>
                <Over>
                  {ctx.isMobile && (
                    <>
                      <Close onClick={handleOpenAddOff}>
                        <CloseIcon />
                      </Close>
                    </>
                  )}
                </Over>
                <AddBlock>
                  <LeadQueue handleOpen={handleOpenAddOff} queues={queues} />
                </AddBlock>
               
              </Modal>
            )}
            <div className="queue">
              <EmailsTable list={customs.results} custom={true} />
            </div>
          </ContentEmail>
        </>
      </Flex>
      {success && (
        <Modal>
          <Overflow onClick={handleSuccessOff}></Overflow>
          <SaveBlock>
            <ModalContent className="success">
              <FlexWrapper className="success_icon">
                <Success />
              </FlexWrapper>
              <TitleSmallBlack>{rootT('saved')}</TitleSmallBlack>
            </ModalContent>
          </SaveBlock>
        </Modal>
      )}
    </MainProfile>
  );
}
