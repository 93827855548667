import styled from 'styled-components';

const MainWrapper = styled.main`
  padding-top: 40px;  
  .main {
    padding: 0 12px 0 27px;
    @media (max-width: 768px) {
      padding: 0 10px; 
    }
  }
  &.impersonate {
    padding-top: 112px; 
    .filtered {
      top: 40px!important;
    }
    .side {
      padding-top: 47px;
      top: 140px;
    }
    @media (max-width: 768px) {
      padding-top: 140px; 
    }
  }
  .impersonated {
    padding-top: 50px; 
    aside {
      top: 119px;
    }
    .leads {
      margin: 19px 0 0 23px;
      &:after {
        display: none
      }
    } 
  }
 
  @media (max-width: 768px) {
    padding-left: 0; 
  }
  @media (min-width: 769px) {
    .lefted {
      transition: .5s;
      margin-left: -78px;
    }
  }
  .detailed {
    .side {
      max-width: 60px;
      ul {
        min-width: 50px;
      }
      ul li {
        padding-left: 15px
      }
      &#accent {
        min-width: fit-content;
      }
    }
    .leads {
      right: auto;
      margin: 158px 0 0 12px;
    }
    .company {
      top: 16%;
      right: auto;
    }
  }
  .hiddenY {
    height: 70vh;
    overflow: hidden
  }
`;

export default MainWrapper;
