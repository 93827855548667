import { useState, useEffect, useContext, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUserFew, fetchUsers } from 'store/slices/users';
import { fetchGAViewIds, setSelectedId } from 'store/slices/gaViewId';
import { useTranslation } from 'react-i18next';
import {
  Block,
  AccentButton,
  PageFluid,
  Modal,
  Overflow,
  FlexWrapper,
  Loader,
  SearchForm,
  Result,
  SecondaryBtnHigh,
  Confirm,
  ButtonsWrap,
  TextBlack,
  FilledButton,
  TitlePage
} from 'base/styled';
import { makeAsOptions } from 'base/utils';
import styled from 'styled-components';
import { ReactComponent as Plus } from 'assets/images/plus_white.svg';
import { ReactComponent as SearchIcon } from 'assets/images/search.svg';
import TableUsers from './TableUsers';
import FormUser from './FormUser';
import Responsive from 'context/responsive';
import { debounce } from 'lodash'; 
import Pagination from 'base/components/Pagination';

const Page = styled(PageFluid)`
  @media (max-width: 768px) {
    padding: 0;
    h1 {
      text-align: right;
    }
  }
`;

const Button = styled(AccentButton)`
  margin-top: 5px;
  svg {
    margin-right: 8px;
  }
`;

const UsersPage = styled(Page)`
width: 95%;
padding-left: 65px;
@media (max-width: 768px) {
  padding-top: 30px;
}
`;

const FlexFluid = styled(FlexWrapper)`
  width: 100%;
  padding: 0 20px 20px 20px;
  button {
    margin: 0 10px;
    height: 37px;
  }
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    .buttons {
      justify-content: center;
    }
  }
`;

const Popup = styled(Block)`
  margin: -2% auto 0;
  padding: 20px 0;
  @media (max-width: 768px) {
    p {
      margin: 2px 0;
    }
  }
`;

const BlockColumn = styled(Block)`
  flex-direction: column;
  padding: 20px 0 0 0;
  @media (max-width: 768px) {
    
  }
`;

export default function Index() {
  const { t: rootT } = useTranslation();
  const { t: homeT } = useTranslation('home');
  const { t: usersT } = useTranslation('users');
  const dispatch = useDispatch();
  const { ids } = useSelector((state) => state.gaViewIdReducer);
  const [show, setShow] = useState();
  const [form, setForm] = useState();
  const [success, setSuccess] = useState();
  const { users, status, errors, postStatus, total_pages } = useSelector((state) => state.usersReducer);
  const [text, setText] = useState('');
  const [search, setSearch] = useState([]);
  const [results, setResults] = useState();
  const [notification, setNotification] = useState(false);

  const [usersWithCheck, setUsersWithCheck] = useState([]);
  const [confirmation, setConfirmation] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  
  const options = makeAsOptions(ids, 'website_id', 'name');
  const ctx = useContext(Responsive);
  const [sentSearch, setSentSearch] = useState(false);

  function handleText(e) {
    setText(e.target.value);
  }
  /* eslint-disable */
  const debouncedSearch = useCallback(
    debounce((query) => {
      const filteredUsers = users?.filter((el) =>
        el.full_name.toLowerCase().includes(query.toLowerCase())
      );
      setSearch(filteredUsers);
    }, 300), 
    [users]
  );
  /* eslint-disable */
  useEffect(() => {
    debouncedSearch(text);
  }, [text, debouncedSearch]);

  function handleSearchSubmit(e) {
    e.preventDefault();
    setSentSearch(true);
    setText(text);
    dispatch(fetchUsers({ users, status, query: text, per_page: 100 }));
  }

  function handleSearchClick(e) {
    e.preventDefault();
    return;
  }

  function handleShow() {
    setShow(true);
    setForm(true);
  }
  console.log(show,form)
  function handleShowOff() {
    setShow();
    setForm();
    setSuccess();
    setConfirmation();
  }

  function handleFetchAll( users, status, query){
    dispatch(fetchUsers({ users, status, query, per_page: 100, page: pageNumber }));
  }

  useEffect(() => {
    if(!sentSearch) {
      handleFetchAll( users, status, text);
    }
    
    return () => {
      status === 'succeed';
    };
    
    /* eslint-disable */
  }, [ users.length, results]);
  /* eslint-enable */

  useEffect(() => {
    if (ids?.length) {
      dispatch(setSelectedId({ value: ids[0].id, label: ids[0].name }));
    }
    if (!ids?.length) {
      dispatch(fetchGAViewIds());
    }
    /* eslint-disable */
  }, [dispatch]);
    /* eslint-enable */

  function handleSelectChange(payload) {
    dispatch(setSelectedId(payload));
  }

  useEffect(() => {
    document.title = 'Users | Visitor Queue';
  }, []);

  useEffect(() => {
    if (errors.length) {
      setNotification(true);
      const timer = setTimeout(() => {
        setNotification(false);
      }, 8000);
      return () => clearTimeout(timer);
    }
  }, [errors]);
 
  useEffect(() => {
    if (postStatus == 'succeed' && success) {
      setNotification(true); 
      const timer = setTimeout(() => {
        setNotification(false);
      }, 2500);
      return () => clearTimeout(timer);
    }
    /* eslint-disable */
  }, [users.length]);
    /* eslint-enable */
  const handleCheckboxChange = (userId) => {
    if (usersWithCheck.includes(userId)) {
      setUsersWithCheck(usersWithCheck.filter(id => id !== userId));
    } else {
      setUsersWithCheck([...usersWithCheck, userId]);
    }
  };
  
  const handleCheckAll = () => {
    if (selectAll) {
      setUsersWithCheck([]);
    } else {
      const allUserIds = (text?.length > 0 ? search : users).map(user => user.id);
      setUsersWithCheck(allUserIds);
    }
    setSelectAll(!selectAll);
  };

  function handleConfirmation() {
    setConfirmation(true);
  }

  function handleDelete() {
    dispatch(deleteUserFew({params: {selected_ids: usersWithCheck.join()}}));
    setConfirmation(false);
    setUsersWithCheck([]);
    setSuccess(true);
  }

  useEffect(() => {   
    const timerIn = setTimeout(() => {
      if(success) {
        setSuccess(false);
      }
    }, 2200);
    return () => clearTimeout(timerIn);
  }, [success]);

  const [pageNumber, setPageNumber] = useState(1);

  function handlePageNext() {
    setPageNumber(prevPageNumber => prevPageNumber + 1);
  }

  function handlePagePrev() {
    setPageNumber(prevPageNumber => prevPageNumber - 1);
  }

  useEffect(() => {
    if (pageNumber >= 1) {
      dispatch(fetchUsers({ users, status, query: '', per_page: 100, page: pageNumber }));
    }
    /* eslint-disable */
  }, [pageNumber, dispatch]);
   /* eslint-enable */
  
  return (
    <UsersPage>
      {status === 'loading' ? (
        <Loader />
      ) : (
        <>
          {!ctx.isMobile && <TitlePage>{usersT('allUsers')}</TitlePage>}
          <BlockColumn>
            { (
              <FlexFluid>
                <FlexWrapper className='buttons'>
                  <Button onClick={handleShow}>
                    <Plus />
                    {usersT('addUser')}
                  </Button>
                  <SecondaryBtnHigh className={usersWithCheck.length > 0 ? '': 'grey' }
                    onClick={usersWithCheck.length > 0 ? handleConfirmation: null }>Delete</SecondaryBtnHigh>
                </FlexWrapper>
                <SearchForm onSubmit={handleSearchSubmit}>
                  <SearchIcon onClick={handleSearchClick} />
                  <input
                    type="text"
                    onChange={handleText}
                    value={text}
                  />
                </SearchForm>
              </FlexFluid>
            )}
            {show && (
              <>
                <Modal>
                  <Overflow onClick={handleShowOff}></Overflow>
                  {form && !success && (
                    <Popup>
                      <FormUser
                        handleShowOff={handleShowOff}
                        options={options}
                        ids={ids}
                        handleSelectChange={handleSelectChange}
                        setSuccess={setSuccess}
                        setShow={setShow}
                        usersPage={true}
                      />
                    </Popup>
                  )}
                </Modal>
              </>
            )}
            {confirmation && (
              <>
                <Modal>
                  <Overflow onClick={handleShowOff}></Overflow>
                  
                  {confirmation && (
                    <Confirm>
                      <TextBlack>{usersT('delete')}</TextBlack>
                      <ButtonsWrap>
                        <SecondaryBtnHigh onClick={handleShowOff}>
                          {homeT('cancel')}
                        </SecondaryBtnHigh>
                        <FilledButton onClick={handleDelete}>
                          {homeT('delete')}
                        </FilledButton>
                      </ButtonsWrap>
                    </Confirm>
                  )}
                </Modal>
              </>
            )}
            {success && <Result>{'User(s) deleted!'}</Result>}
            {postStatus == 'succeed' &&  notification && <Result>{rootT('saved')}</Result>}
            {errors.length > 0 && postStatus != 'succeed' && notification && <Result className='error'>
              {errors == 'Validation failed: Email User with same email already exists.' ?
                'Error: User with same email already exists.' : errors}
            </Result>}
            <TableUsers
              usersList={text?.length > 0 ? search : users}
              handleFetchAll={handleFetchAll}
              ids={ids}
              setResults={setResults}
              handleCheckAll={handleCheckAll}
              setSuccess={setSuccess}
              handleCheckboxChange={handleCheckboxChange}
              usersWithCheck={usersWithCheck}
              selectAll={selectAll}
            />
          </BlockColumn>
        </>
      )}
      { Pagination(total_pages, pageNumber, handlePageNext, handlePagePrev, setPageNumber)}
    </UsersPage>
  );
}
