import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Sidebar, MenuItem, List, InlineLink, Block, Modal, Overflow } from 'base/styled';
import { useHistory, useLocation } from 'react-router-dom';
import {selectRouteByKey} from 'navigation/routes.js';
import styled from 'styled-components';
import { arrayOf, shape, bool, string, elementType } from 'prop-types';
import { ReactComponent as Arrow } from 'assets/images/arrow.svg';
import img from 'assets/images/angle_left.svg';
import Responsive from 'context/responsive';
import RequestPaid from 'pages/Personalize/RequestPaid';
import { fetchAllDetails } from 'store/slices/account';
import Terms from './Terms';
import network from 'base/network';

const StyledLink = styled(InlineLink)`
  color: #3b3e45;
  font-weight: ${props => props?.active ? '600' : '400'};
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: .2s;
  transform: ${ props => props.active ? 'scale3d(1.1, 1.1, 1.1);' : 'none'};
  &:hover, .active {
    color: ${props => props.theme.colors.black}
    transition: .2s;
    transform: scale3d(1.07, 1.07, 1.07);
    svg {
      fill: #3b3e45;
      &.bench path {
        stroke: ${props => props.theme.colors.black};
      }
    }
  }
  .active { 
    tranform: scale3d(1.07, 1.07, 1.07);
    svg {
      width: auto;
    }
  }
  svg {
    width: 28px;
    fill: ${props => props?.active ? props.theme.colors.black : '#85929B'};
    &.bench path {
      stroke: ${props => props?.active ? props.theme.colors.black : '#85929B'};
    }
  }
  p.receipts {
    line-height: 16px;
    margin-top: 16px;
  }
  p.span {
  margin: 0;
  }
`;

const ListNav = styled(List)`
  position: relative;
  min-width: 130px;
  .leads,
  .company {
    display: none;
    position: relative;
    &.d-block {
      display: block
    }
  }
  .leads {
    margin: 158px 0 0 60px;
    &:after {
    content: '';
    display: block;
    position: absolute;
    top: -20px;
    left: 0;
    height: 390px;
    width: 1.5px;
    background: #85929B;
    }
  }
  .company {
    margin: -105px 0 0 13px;
    top: auto;
    right: -25px;
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: -60px;
      left: 0;
      height: 390px;
      width: 1.5px;
      background: #85929B;
      }
  }
  .leadsItem {
    &:hover {
      .leads {
        display: block;
      }
    }
  }
  .companyItem {
    &:hover {
      .company {
        display: block;
      }
    }
  }

  @media (max-width: 768px) {
    .leads {
      top: 39px;
      right: auto;
      left: 13px;
      background: #fff;
      width: 233px;
      box-shadow: rgba(238, 62, 38, 0.06) 4px 12px 32px 18px;
      border-radius: 15px;
      margin: 158px 0 0 36px;
      z-index: 1;
      &:after {
        display: none;
      }
    }
    .company {
      top: 277px;
      right: auto;
      left: 25px;
      background: #fff;
      width: 233px;
      box-shadow: rgba(238, 62, 38, 0.06) 4px 12px 32px 18px;
      border-radius: 15px;
      margin: -272px 0 0 13px;
      z-index: 1;
      &:after {
        display: none;
      }
    }
    svg.arrow {
      margin-left: 0;
    }
    a {
      padding-left: 0px;
    }
    .leadsItem, .companyItem {
      a {
        position: absolute;
        z-index: -2;
        background: url(${img}) no-repeat 10% center
      }
    .leads, .company {
      li {
        width: fit-content;
        a {
          position: static;
          z-index: auto;
          background: inherit;
        }
      }
      
    }
    }
  }
`;

const AccentBlock = styled(Block)`
box-shadow: 4px 12px 32px 18px rgba(238, 62, 38, 0.06);
`;

const LeadMenuItem = styled(MenuItem)`
background-color: ${ props => props?.active ? 'transparent' : 'transparent'};
padding: 15px 10px 15px 15px;
border-radius: 8px;
white-space: nowrap;
svg:not(.arrow) {
  fill: ${props => props?.active ? props.theme.colors.black : '#8B939A'};
  margin: 0 auto;
}
a {
  color: #3b3e45;
  transform: ${ props => props.$active ? 'scale3d(1.3, 1.3, 1.3);' : 'none'};
}
div {
  background:  ${ props => props?.active ? 'transparent' : 'transparent'}; 
  display: inline-grid;
  margin: auto;
  align-items: center;
  margin-right: 8px
}
.arrow {
  fill: ${ props => props?.active ? 'black' : 'transparent'};
  margin-left: 8px;
}
`;

const Icon = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  padding: 3px;
  margin: 0 20px 0 5px;
  background: transparent;
`;

const Popup = styled(Block)`
  margin: -2% auto 0;
  padding: 20px;
  @media (max-width: 480px) {
    width: 96%;
    .success {
      margin: 0 auto;
    }
  }
`;

export default function SideNav({ routes, leadRoutes, companyRoutes, openUser }) {
  const { t } = useTranslation('sidenav');
  const [show, setShow] = useState(false);
  const { pathname } = useLocation();
  const isActivePath = (currentPath, routerPath) => currentPath == routerPath;
  const isActiveMenu = (currentPath) => {
    const leadRoutes = selectRouteByKey('leadsNav');
    if (leadRoutes.includes(currentPath)) {
      return true;
    }
    return false;
  };
  const ctx = useContext(Responsive);

  const dispatch = useDispatch();

  let location = useLocation();
  const history = useHistory();

  const { details, subscription } = useSelector(
    (state) => state.accountReducer
  );
  const [isHoveredLeads, setIsHoveredLeads] = useState(false);
  const [isHoveredCompany, setIsHoveredCompany] = useState(false);

  const handleMouseEnter = (item) => {
    item == 'leads' ? setIsHoveredLeads(true) : setIsHoveredCompany(true);
  };

  const handleMouseLeave = () => {
    setIsHoveredLeads(false);
    setIsHoveredCompany(false);
  };

  const [isLeadsActive, setIsLeadsActive] = useState(false);
  const [isCompanyActive, setIsCompanyActive] = useState(false);
  useEffect(() => {
    if(pathname.includes('dashboard') ||
    pathname.includes('auto-assign') ||
    pathname.includes('hidden') ||
    pathname.includes('notifications')) {
      setIsLeadsActive(true);
      setIsCompanyActive(false);
    } else if(pathname.includes('account') ||
    pathname.includes('company') ||
    pathname.includes('users') ||
    pathname.includes('receipts') ||
    pathname.includes('websites') ||
    pathname.includes('integrations')) {
      setIsCompanyActive(true);
      setIsLeadsActive(false);
    } else {
      setIsCompanyActive(false);
      setIsLeadsActive(false);
    }
  }, [pathname]);

  useEffect(() => {
    if(details?.length === 0) {
      dispatch(fetchAllDetails({ details }));
    }  
  }, [dispatch, details]);

  useEffect(() => {
    
    if(details?.trial_end_date == null) {
      ctx.setIsTrial(details?.trial_end_date);
    } 
    if(subscription?.name == 'Free lite plan') {
      ctx.setIsLiteFree(true);
    }
    if(details?.available_leads_count <= 0) {
      ctx.setHitLimit(details.lead_reset_date);
    }
    /* eslint-disable */
  }, [dispatch, details]);
  /* eslint-enable */

  useEffect(() => {
    if(location.pathname.includes('/personalize') && details?.length !== 0 && 
    details?.settings?.allow_personalisation != '1'){
      history?.replace("/company");
      setShow(true);
    } 
    /* eslint-disable */
  }, [location.pathname, details?.length]);
  /* eslint-enable */
  const handleShow = () => {
    if(details?.settings?.allow_personalisation == '0' || !details?.settings?.allow_personalisation) {
      setShow(true);
    } else {
      ctx.setShowNewConversion(false);
      return;
    }
  };

  function handleShowOff(){
    setShow(false);
  }

  let role = ctx.impersonate ? (ctx.impersonate.role === 'admin' && ctx.role === 'admin') : ctx.role === 'admin';

  const handleRedirect = () => {
    network.get('/api/account/billing_portal', {params: {url: window.location.href}})
      .then(data => {
        window.location.href = data.data.results;
      }
      );
  };

  return (
    <Sidebar className={ openUser ? "side mobile-side" : "side" } 
      id={!ctx.isMobile && (isHoveredLeads || isHoveredCompany) ? "accent" : ''}>
      {show && (
        <>
          <Modal>
            <Overflow onClick={handleShowOff}></Overflow>
            <Popup>
              <RequestPaid handleShowOff={handleShowOff} />
            </Popup>
          </Modal>
        </>
      )}
      <ListNav>
        {routes.map((route, index) =>
          role ? (
            <MenuItem
              key={`${index}-${route.name}`}
              active={isActiveMenu(pathname) ? true : null}
              className={route.className}
              onMouseEnter={(route.name == 'leads' || route.name == 'company') 
                ? () => handleMouseEnter(route.name) : null}
              onMouseLeave={handleMouseLeave}
            >
              <StyledLink
                to={(
                  details?.settings?.allow_personalisation == '1'
                    ? route.path
                    : route.access
                )}
                active={(isActivePath(pathname, route.path) ? 1 : 0) ||
                (isCompanyActive && route.name=='company') ? 1 : 0||
                (isLeadsActive && route.name == 'leads') ? 1 : 0}
                onClick={route.isPersonalization ? handleShow : null}
                style={{pointerEvents: details?.active == false ? 'none' : ''}}
               
              >
                {isActivePath(pathname, route.path) ? route.iconActive : route.icon }
                
                {!ctx.details && t(route.name)}
              </StyledLink>
              {route.leadsNav && (
                <div className={isHoveredLeads ? "d-block leads" : "leads"}>
                  <>
                    <List>
                      {leadRoutes.map((route, index) => (
                        <LeadMenuItem
                          key={`${index}-${route.name}`}
                          active={isActivePath(pathname, route.path) ? 1 : 0}
                          className={route.className}
                        >
                          <StyledLink
                            to={route.path}
                            active={isActivePath(pathname, route.path) ? 1 : 0}
                            style={{pointerEvents: details?.active == false ? 'none' : ''}}
                          >
                            <Icon className={isActivePath ? 'active' : ''}>
                              {route.icon}
                            </Icon>
                            <p>{t(route.name)}</p>
                            
                            {isActivePath && <Arrow className="arrow" />}
                          </StyledLink>
                        </LeadMenuItem>
                      ))}
                    </List>
                  </>
                </div>
              )}
              {route.subNav && (
                <div className={isHoveredCompany ? "d-block company" : "company"}>
                  <>
                    <List>
                      {companyRoutes.map((route, index) => (
                        route.name != 'integration' && 
                        <LeadMenuItem
                          key={`${index}-${route.name}`}
                          active={isActivePath(pathname, route.path) ? 1 : 0}
                          className={(!details.stripe_customer_id && route.name == 'receipts') ? 
                            route.className + ' d-none': route.className }
                          onClick={route.name == 'receipts' ? () => handleRedirect(route.name) : null }
                        >
                          <StyledLink
                            to={route.path}
                            active={isActivePath(pathname, route.path) ? 1 : 0}
                            style={{pointerEvents: details?.active == false ? 'none' : ''}}
                          >
                            <Icon className={isActivePath ? 'active' : ''}>
                              {route.icon}
                            </Icon>

                            <p className={route.name}>{t(route.name)}
                              <p className='span'>{t(route.subname)}</p>
                            </p>
                            
                            {isActivePath && <Arrow className="arrow" />}
                          </StyledLink>
                        </LeadMenuItem>
                      ))}
                    </List>
                  </>
                </div>
              )}
            </MenuItem>
          ) : route.user ? (
            <MenuItem
              key={`${index}-${route.name}`}
              active={isActiveMenu(pathname) ? true : null}
              className={route.className}
            >
              <StyledLink
                to={
                  details?.settings?.allow_personalisation
                    ? route.path
                    : route.access
                }
                active={isActivePath(pathname, route.path) ? 1 : 0}
                onClick={route.isPersonalization ? handleShow : null}
                style={{pointerEvents: details?.active == false ? 'none' : ''}}
              >
                {route.icon}
                {!ctx.details && t(route.name)}
              </StyledLink>
              {route.leadsNav && (
                <div className="leads">
                  <AccentBlock>
                    <List>
                      {leadRoutes.map((route, index) =>
                        route.user ? (
                          <LeadMenuItem
                            key={`${index}-${route.name}`}
                            active={isActivePath(pathname, route.path) ? 1 : 0}
                            className={route.className}
                          >
                            <StyledLink
                              to={route.path}
                              active={
                                isActivePath(pathname, route.path) ? 1 : 0
                              }
                              style={{pointerEvents: details?.active == false ? 'none' : ''}}
                            >
                              <Icon className={isActivePath ? 'active' : ''}>
                                {route.icon}
                              </Icon>

                              {!ctx.details && t(route.name)}
                              {isActivePath && <Arrow className="arrow" />}
                            </StyledLink>
                          </LeadMenuItem>
                        ) : null
                      )}
                    </List>
                  </AccentBlock>
                </div>
              )}
              {route.subNav && (
                <div className="company">
                  <AccentBlock>
                    <List>
                      {companyRoutes.map((route, index) => (
                        <LeadMenuItem
                          key={`${index}-${route.name}`}
                          active={isActivePath(pathname, route.path) ? 1 : 0}
                          className={route.className}
                        >
                          <StyledLink
                            to={route.path}
                            active={isActivePath(pathname, route.path) ? 1 : 0}
                            style={{pointerEvents: details?.active == false ? 'none' : ''}}
                          >
                            <Icon className={isActivePath ? 'active' : ''}>
                              {route.icon}
                            </Icon>

                            {!ctx.details && t(route.name)}
                            {isActivePath && <Arrow className="arrow" />}
                          </StyledLink>
                        </LeadMenuItem>
                      ))}
                    </List>
                  </AccentBlock>
                </div>
              )}
            </MenuItem>
          ) : null
        )}
      </ListNav>  
      {!isHoveredCompany && !isHoveredLeads && <Terms />}   
    </Sidebar>
  );
}

SideNav.propTypes = {
  details: bool,
  routes: arrayOf(shape({
    name: string,
    path: string,
    exact: bool,
    main: elementType
  })),
  leadRoutes: arrayOf(shape({
    name: string,
    path: string,
    exact: bool,
    main: elementType
  })),
  companyRoutes: arrayOf(shape({
    name: string,
    path: string,
    exact: bool,
    main: elementType
  })),
  openUser: bool
};
